// extracted by mini-css-extract-plugin
export var container = "page-module--container--9cafc";
export var containerTitle = "page-module--containerTitle--ddd2b";
export var content = "page-module--content--6ac96";
export var date = "page-module--date--88389";
export var link = "page-module--link--b2680";
export var mainTitle = "page-module--mainTitle--09f8f";
export var next = "page-module--next--a60c3";
export var paragraphContent = "page-module--paragraphContent--d2b20";
export var prev = "page-module--prev--1ffc1";
export var prose = "page-module--prose--39c9e";